.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #EFC3CA;
}

.logo {
  margin-right: auto;
  width: 50px;
  height: auto;
}

.navbar-links {
  display: flex;
}

.navbar-button {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar-button2 {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.navbar-button:hover {
  background-color: #1c5796;
}

.navbar-button2:hover {
  background-color: #29733a;
}

.navbar-profile {
  position: relative;
  cursor: pointer;
}

.navbar-profile img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.dropdown-content {
  position: absolute;
  top: 50px;
  right: 0;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 8px 0;
  z-index: 1;
}

.dropdown-username {
  padding: 8px 16px;
  text-align: left;
  background-color: transparent;
  cursor: text;
  border-bottom: 1px solid #ddd;
}

.dropdown-button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  text-align: left;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.dropdown-button:hover {
  background-color: #ddd;
}

.imprint-button {
  margin: 0 10px;
  padding: 8px 16px;
  background-color: gray;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.imprint-button:hover {
  background-color: lightgray;
}

/* Media Queries */
@media (max-width: 768px) {
  .navbar {
    flex-direction: row;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    margin-top: 10px;
  }

  .navbar-button,
  .navbar-button2,
  .imprint-button {
    margin: 5px 0;
  }
}
