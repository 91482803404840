/* Lobby.css */

/* Default styles */
.lobby {
    text-align: center;
    margin-top: 50px;
}

.lobby-title {
    color: #EFC3CA; 
    font-size: 32px;
}

.lobby-result-header {
    color: #EFC3CA; 
    font-size: 26px;
}

.lobby-topic {
    color: #EFC3CA; 
    font-size: 26px;
    margin-top: 10px;
}

.lobby-result-topic {
    color: #EFC3CA; 
    font-size: 20px;
    margin-top: 10px;
}

.lobby-form {
    display: flex;
    align-items: center;
    justify-content: center;
}

.lobby-input {
    color: #EFC3CA;
    font-size: 30px;
    margin-right: 20px;
}

.lobby-button {
    width: 200px;
    height: 50px;
    background-color: #EFC3CA; /* Weiß */
    color: #fff; /* Hintergrundfarbe */
    border: none;
    border-radius: 25px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.lobby-group-button,
.lobby-result-button {
    width: 200px;
    height: 50px;
    background-color: #EFC3CA; /* Weiß */
    color: #fff; /* Hintergrundfarbe */
    border: none;
    border-radius: 25px;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s, color 0.3s;
}

.lobby-button:hover {
    background-color: #ed7a8d;
}

/* Standard-Styling für die Liste */
.lobby-word-list {
    margin-top: 20px;
    list-style-position: inside;
    text-align: left; /* Liste links ausrichten */
    margin-bottom: 20px;
}

.lobby-result-word-list {
    margin-top: 20px;
    list-style-position: inside;
    text-align: left; /* Liste links ausrichten */
    margin-bottom: 20px;
    column-count: 1; /* Teilt die Liste in 1 Spalte */
}

.lobby-result-buttons {
    display: flex;
    justify-content: space-evenly;
}

/* Styling für größere Bildschirme, in denen die Liste in zwei Spalten aufgeteilt werden soll */
@media screen and (min-width: 768px) {
    .lobby-word-list {
        column-count: 2; /* Teilt die Liste in 2 Spalten */
        column-gap:normal; /* Abstand zwischen den Spalten */
    }
}

.lobby-word {
    color: #EFC3CA; /* Helleres Grau */
    font-size: 18px;
}

.Ai-Words {
    color: #EFC3CA; /* Helleres Grau */
    font-size: 18px;
    flex-direction: row;
}

.lobby-result-list {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }


/* Responsive styles */
@media (max-width: 1300px) {
    /* Modify styles for screens smaller than 768px */
    .lobby-title {
        font-size: 24px;
    }

    .lobby-topic {
        font-size: 18px;
    }

    .lobby-input {
        font-size: 24px;
    }

    .lobby-button {
        margin-top: 10px;
    }
    
    .lobby-word {
        font-size: 18px;
    }

    .lobby-form {
        flex-direction: column;
    }

    .lobby-result-list {
        display: flex;
        flex-direction: column;
      }
}

@media (max-width: 480px) {
    /* Modify styles for screens smaller than 480px */
    .lobby-title {
        font-size: 20px;
    }

    .lobby-topic {
        font-size: 14px;
    }

    .lobby-input {
        font-size: 20px;
    }

    .lobby-word {
        font-size: 14px;
    }
    .lobby-form {
        flex-direction: column;
    }

    .lobby-result-list {
        display: flex;
        flex-direction: column;
      }
}