.cookie-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: black;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.cookie-banner p {
  color: white;
  font-size: 14px;
  margin: 0;
}

.cookie-banner button {
  background-color: green;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-top: 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cookie-banner button:hover {
  background-color: darkgreen;
}

.cookie-img {
  width: 50px;
  animation: roll-in 5s forwards;
}

@keyframes roll-in {
  0% {
    transform: translateX(-2000%) rotate(-3600deg);
  }
  100% {
    transform: translateX(0) rotate(0);
  }
}

/* Media queries for responsive design */

@media (max-width: 768px) {
  .cookie-banner {
    flex-direction: column;
  }
  
  .cookie-banner button {
    margin-top: 10px;
  }
  
  .cookie-img {
    width: 30px;
    animation: roll-in 3s forwards;
  }
  @keyframes roll-in {
    0% {
      transform: translateX(-1000%) rotate(-3600deg);
    }
    100% {
      transform: translateX(0) rotate(0);
    }
}
}