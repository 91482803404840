/* Home.css */

.header {
  text-align: center;
  margin-top: 50px;
}

.header h2 {
  color: #EFC3CA;
  font-size: 32px;
}

.header p {
  color: #EFC3CA;
  font-size: 18px;
  margin-top: 10px;
}

.concept {
  display: flex;
  align-items: center;
  justify-content: center;
}

.full-logo {
  align-items: center;
  width: 200px;
  height: auto;
}

.concept-details h3 {
  color: #EFC3CA;
  font-size: 24px;
  margin-bottom: 10px;
}

.concept-details p {
  color: #EFC3CA;
  font-size: 16px;
}

.features h3 {
  color: #EFC3CA;
  font-size: 24px;
  margin-bottom: 20px;
}

.features ul {
  list-style-type: none;
  align-items: center;
}

.features li {
  align-items: center;
  color: #EFC3CA; /* Helleres Grau */
  font-size: 18px;
  margin-bottom: 10px;
}

.cta {
  margin-top: 50px;
}

.cta-button {
  width: 200px;
  height: 50px;
  background-color: #EFC3CA; /* Weiß */
  color: #fff; /* Hintergrundfarbe */
  border: none;
  border-radius: 25px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
  margin-bottom: 100px;
}

.cta-button:hover {
  background-color: #ed7a8d;
}

.home {
  align-items: center;
  text-align: center;
}

/* Media Queries */
@media (max-width: 768px) {
  /* Styles for screens smaller than 768px */
  .header h2 {
    font-size: 24px;
  }

  .header p {
    font-size: 16px;
  }

  .concept-details h3 {
    font-size: 20px;
  }

  .concept-details p {
    font-size: 14px;
  }

  .features h3 {
    font-size: 20px;
  }

  .features li {
    font-size: 16px;
  }

  .cta-button {
    width: 150px;
    height: 40px;
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  /* Styles for screens smaller than 480px */
  .header h2 {
    font-size: 20px;
  }

  .header p {
    font-size: 14px;
  }

  .concept-details h3 {
    font-size: 18px;
  }

  .concept-details p {
    font-size: 12px;
  }

  .features h3 {
    font-size: 18px;
  }

  .features li {
    font-size: 14px;
  }

  .cta-button {
    width: 120px;
    height: 30px;
    font-size: 14px;
  }
}