/* Pages.css */

body {
  font-family: Arial, sans-serif;
  background-color: #fff;
  height: auto;
  margin: 0;
  padding: 0;
}

.container {
  width: 90%;
  max-width: 600px;
  margin: auto;
  margin-top: 50px;
  background-color: #EFC3CA;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  text-align: center;
  margin-top: 20px;
}

.text {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
}

.underline {
  width: 61px;
  height: 6px;
  background-color: #fff;
  border-radius: 9px;
  margin: 15px auto;
}

.inputs {
  margin-top: 35px;
}

.input {
  margin-bottom: 25px;
}

.input img{
  width: 25px;
  height: auto;
  margin-right: 10px;
  vertical-align: middle;
}

.input input {
  width: calc(100% - 50px);
  height: 40px;
  background: transparent;
  border: #000;
  outline: none;
  color: #fff;
  font-size: 16px;
}

.input input::placeholder {
  color: white;
}

.submit-container {
  display: flex;
  flex-direction:row; /* Added */
  align-items: center; /* Added */
  margin-top: 35px;
}

.nosubmit,
.submit {
  width: 100%;
  height: 50px;
  color: green;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top:10px;
}

.gray {
  background-color: #fff;
  color: gray;
}

.inputfield {
  color: white;
}

.submit:hover {
  background-color: #ed7a8d;
  color: rgb(177, 235, 177);
}

.nosubmit:hover {
  background-color: #ed7a8d;
  color: #fff
}

.error {
  text-align: center;
  color: red;
  margin-top: 5px;
  font-size: 1rem;
}

.imprint{
  text-align: center;
  margin-top:10px;
  color: #EFC3CA;
}

.imprint-but{
  background-color: #ed7a8d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top:10px;
  transition: background-color 0.3s;
}
    
.imprint-but:hover {
  background-color: gray;
}

.usage-statistic {
  text-align: center;
  margin-top: 10px;
  color: #EFC3CA;
}

.usage-table {
  margin: 0 auto;
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #ed7a8d;
  color: white;
  text-align: center;
}

.table-data {
    background-color: #fff;
    color: #000;
    text-align: center;
}


/* Media Queries */

@media screen and (max-width: 768px) {
  .header {
    margin-top: 10px;
  }

  .submit {
    height: 40px;
    margin-top: 10px;

  }
  .container {
    width: 90%;
    margin-top: 100px;
    margin-bottom: auto;
    padding: 10px;
    height:fit-content; /* Added */
    align-self: center;
    height: auto; /* Adjust the height as per your requirement */
  }
    
  .submit-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; /* Added */
      margin-top: 20px;
    }
    
  .submit,
  .nosubmit {
      width: 80%; /* Adjust the width as per your requirement */
      height: 50px;
      color: green;
      background-color: #fff;
      border: none;
      border-radius: 25px;
      font-size: 16px;
      font-weight: 700;
      margin-top: 10px;
      cursor: pointer;
      transition: background-color 0.3s;
      display: flex;
      align-items: center;
      justify-content: center; /* Added */
    }

    .gray {
      background-color: #fff;
      color: gray;
    }
  
  
  .text {
    font-size: 20px;
  }
  
  .inputs {
    margin-top: 20px;
  }
  
  .input input {
    height: 30px;
    font-size: 14px;
  }
  
  .submit-container {
    margin-top: 20px;
  }
  
  .imprint-but {
    padding: 8px 16px;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .usage-statistics {
    margin-top: 5px;
  }
}